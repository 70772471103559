import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    preventScroll: false,
    expandedSubmenu: '',
    openModal: '',
    exitHref: '',
    returnFocusTo: '',
    preventPlay: true,
    playingVideo: '',
    playingVideoId: '',
    activeDot: '',
    selectedChapter: '',
    selectedChapterTime: 0,
    playingVideoTime: 0,
    textZoom: false,
    desktopZoom: false,
  });

  const updateGlobalState = (updatedState) => {
    setGlobalState({ ...globalState, ...updatedState });
  };

  return (
    <GlobalContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const getApiEndpoint = () => {
  if (window.location.hostname.indexOf('klickcloud.net') >= 0 || window.location.hostname.indexOf('perserishcp.com') >= 0) {
      return '/api';
  } else {
      return 'https://perserishcp-uat.klickcloud.net/api';
  }
}
