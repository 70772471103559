exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-internal-server-error-js": () => import("./../../../src/pages/500-internal-server-error.js" /* webpackChunkName: "component---src-pages-500-internal-server-error-js" */),
  "component---src-pages-copay-assistance-js": () => import("./../../../src/pages/copay-assistance.js" /* webpackChunkName: "component---src-pages-copay-assistance-js" */),
  "component---src-pages-depot-delivery-system-and-administration-js": () => import("./../../../src/pages/depot-delivery-system-and-administration.js" /* webpackChunkName: "component---src-pages-depot-delivery-system-and-administration-js" */),
  "component---src-pages-downloadable-resources-js": () => import("./../../../src/pages/downloadable-resources.js" /* webpackChunkName: "component---src-pages-downloadable-resources-js" */),
  "component---src-pages-efficacy-js": () => import("./../../../src/pages/efficacy.js" /* webpackChunkName: "component---src-pages-efficacy-js" */),
  "component---src-pages-identifying-patients-js": () => import("./../../../src/pages/identifying-patients.js" /* webpackChunkName: "component---src-pages-identifying-patients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initiation-regimen-js": () => import("./../../../src/pages/initiation-regimen.js" /* webpackChunkName: "component---src-pages-initiation-regimen-js" */),
  "component---src-pages-injection-site-pain-and-reactions-js": () => import("./../../../src/pages/injection-site-pain-and-reactions.js" /* webpackChunkName: "component---src-pages-injection-site-pain-and-reactions-js" */),
  "component---src-pages-lai-antipsychotic-therapy-video-js": () => import("./../../../src/pages/lai-antipsychotic-therapy-video.js" /* webpackChunkName: "component---src-pages-lai-antipsychotic-therapy-video-js" */),
  "component---src-pages-lai-needle-sizes-js": () => import("./../../../src/pages/lai-needle-sizes.js" /* webpackChunkName: "component---src-pages-lai-needle-sizes-js" */),
  "component---src-pages-mechanism-of-action-js": () => import("./../../../src/pages/mechanism-of-action.js" /* webpackChunkName: "component---src-pages-mechanism-of-action-js" */),
  "component---src-pages-plasma-levels-js": () => import("./../../../src/pages/plasma-levels.js" /* webpackChunkName: "component---src-pages-plasma-levels-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-storage-and-preparation-js": () => import("./../../../src/pages/storage-and-preparation.js" /* webpackChunkName: "component---src-pages-storage-and-preparation-js" */),
  "component---src-pages-video-gallery-js": () => import("./../../../src/pages/video-gallery.js" /* webpackChunkName: "component---src-pages-video-gallery-js" */),
  "component---src-pages-wac-pricing-disclosure-js": () => import("./../../../src/pages/wac-pricing-disclosure.js" /* webpackChunkName: "component---src-pages-wac-pricing-disclosure-js" */)
}

